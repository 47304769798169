import { memo } from 'react';
import { BlogCardMemo, BlogCardProps } from '../blog-card/blog-card';
import { CardScrollerMemo } from '../../../cards/card-scroller/card-scroller';
import { CardListHeaderMemo } from '../../../cards/card-list-header/card-list-header';
import {
  FOCUS_SCROLL_CONTAINER_ATTRIBUTE,
  FOCUS_SCROLL_CONTAINER_TARGET_ATTRIBUTE,
} from '../../../../utils/nav-system';
import styles from './blog-card-list.module.css';

export type BlogCardListItemProps = Pick<
  BlogCardProps,
  'id' | 'title' | 'description' | 'url' | 'imageUrl'
>;

export type BlogCardListProps = {
  id: string;
  items: BlogCardListItemProps[];
  headerTitle: string;
  headerTitleLink?: string;
  headerIcon?: React.ReactNode;
  headerSize?: 'medium' | 'large';
  headerButtonLink?: string;
  headerButtonLinkText?: string;
};

export function BlogCardList({
  id,
  items,
  headerTitle,
  headerTitleLink,
  headerIcon,
  headerSize,
  headerButtonLink,
  headerButtonLinkText,
}: BlogCardListProps) {
  const rootId = `blog-card-list-${id}`;
  const headerId = `blog-card-list-header-${id}`;
  const scrollerId = `blog-card-list-scroller-${id}`;

  // No items, no render.
  if (!items.length) return null;

  return (
    <div
      id={rootId}
      className={`${styles.root} ${styles[`${headerSize}HeaderSize`]}`}
      role="region"
      aria-labelledby={headerId}
      {...{
        [FOCUS_SCROLL_CONTAINER_ATTRIBUTE]: 'y',
      }}
    >
      <div
        className={styles.scrollToTarget}
        {...{
          [FOCUS_SCROLL_CONTAINER_TARGET_ATTRIBUTE]: 'y',
        }}
      ></div>
      <CardListHeaderMemo
        id={headerId}
        className={styles.header}
        title={headerTitle}
        titleLink={headerTitleLink}
        icon={headerIcon}
        size={headerSize}
        buttonLink={headerButtonLink}
        buttonLinkText={headerButtonLinkText}
      />
      <CardScrollerMemo
        className={styles.scroller}
        scrollerId={scrollerId}
        cardSize="large"
        cardOrientation="landscape"
        hideScrollIndicator={true}
      >
        {items.map((item, index) => (
          <BlogCardMemo
            key={index}
            id={item.id}
            title={item.title}
            description={item.description}
            url={item.url}
            imageUrl={item.imageUrl}
          />
        ))}
      </CardScrollerMemo>
    </div>
  );
}

export const BlogCardListMemo = memo(BlogCardList);

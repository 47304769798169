import { SvgIconMemo, SvgIconProps } from './svg-icon';

export function ArrowForwardIcon({ viewBox = '0 0 32 32', ...restProps }: SvgIconProps) {
  return (
    <SvgIconMemo viewBox={viewBox} {...restProps}>
      <path
        d="M28.055 13.96L19.095 5L17.315 6.78L25.285 14.75H3.005V17.25H25.775L17.805 25.22L19.585 27L28.055 18.53C29.315 17.27 29.315 15.22 28.055 13.96Z"
        fill="currentColor"
      />
    </SvgIconMemo>
  );
}

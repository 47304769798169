import { memo, useRef } from 'react';
import Image from 'next/image';
import { InternalLinkMemo } from '../../../internal-link/internal-link';
import { useCard } from '../../../../hooks/use-card';
import { sendMixPanelEvent } from '../../../../utils/mixpanel';
import {
  ARROW_NAV_TARGET_CLASS,
  FOCUS_SCROLL_CONTAINER_ATTRIBUTE,
} from '../../../../utils/nav-system';
import { IMAGE_SIZES, IMAGE_OPTIMIZATION_QUALITY } from '../../../../constants.mjs';
import styles from './blog-card.module.css';
import { ArrowForwardIcon } from '../../../svg-icons/arrow-forward-icon';

export type BlogCardProps = {
  // Item data.
  id: string;
  title: string;
  description: string;
  url: string;
  imageUrl: string;
  // Other props.
  onActivate?: () => void;
  onDeactivate?: () => void;
};

export function BlogCard({ onActivate, onDeactivate, ...item }: BlogCardProps) {
  const ref = useRef<HTMLAnchorElement>(null);
  const { isActive, onPointerActivate, onPointerDeactivate, onFocusActivate, onBlurDeactivate } =
    useCard(ref, {
      onActivate,
      onDeactivate,
    });

  return (
    <InternalLinkMemo
      ref={ref}
      className={`${styles.root} ${isActive ? styles.isActive : ''} ${ARROW_NAV_TARGET_CLASS}`}
      href={item.url}
      draggable={false}
      onPointerDown={onPointerActivate}
      onPointerUp={onPointerActivate}
      onPointerMove={onPointerActivate}
      onPointerEnter={onPointerActivate}
      onPointerLeave={onPointerDeactivate}
      onFocus={onFocusActivate}
      onBlur={onBlurDeactivate}
      onClick={() => {
        sendMixPanelEvent('BlogCardClicked', {
          id: item.id,
          name: item.title,
        });
      }}
      {...{
        [FOCUS_SCROLL_CONTAINER_ATTRIBUTE]: 'x',
      }}
    >
      <span className={styles.bgActive} />
      <span className={styles.imageContainer}>
        <Image
          key={item.imageUrl}
          className={styles.image}
          src={item.imageUrl}
          alt={item.title}
          draggable={false}
          fill
          quality={IMAGE_OPTIMIZATION_QUALITY}
          sizes={IMAGE_SIZES.CardLarge}
        />
        <span className={styles.imageHighlight} />
      </span>
      <span className={styles.title}>{item.title}</span>
      <span className={styles.description}>{item.description}</span>
      <ArrowForwardIcon className={styles.icon} />
    </InternalLinkMemo>
  );
}

export const BlogCardMemo = memo(BlogCard);

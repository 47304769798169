import type { GetIndexPageDataQuery } from '../../../../.gql/graphql';
import { useMemo } from 'react';

export function useBlogPosts(data: GetIndexPageDataQuery) {
  return useMemo(
    () =>
      data.blogPosts?.map((blogPost) => {
        return {
          id: blogPost.id,
          title: blogPost.title || '',
          description: blogPost.description || '',
          url: blogPost.url || '',
          imageUrl: blogPost.image?.url || '',
        };
      }) || [],
    [data],
  );
}
